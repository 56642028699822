.fade-enter {
    opacity: 0;
    transform: translateY(-20px);
}
.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms ease-in, transform 300ms ease-in;
}
.fade-exit {
    opacity: 1;
    transform: translateY(0);
}
.fade-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 300ms ease-in, transform 300ms ease-in;
}

@keyframes moveBackground {
    0% { background-position: 0% 0%; }
    100% { background-position: 100% 100%; }
}

/* Класс для анимации фона */
.animated-background {
    position: relative;
    overflow: hidden;
}

.animated-background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 300%;
    height: 300%;
    background-image: radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0.2), transparent 25%),
    radial-gradient(circle at 70% 70%, rgba(255, 255, 255, 0.15), transparent 20%),
    radial-gradient(circle at 40% 70%, rgba(255, 255, 255, 0.1), transparent 25%);
    background-position: center;
    animation: moveBackground 5s ease-in-out infinite alternate;
    z-index: 1;
}

canvas {
    filter: blur(1px) brightness(1.5); /* Легкий blur и повышение яркости */
}